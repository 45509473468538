<template>
	<div>
		<CustomTable
			:show-filter-status="true"
			:show-filter-types="true"
			:show-sort-by="true"
			:recent-filter="recentFilters"
			:total-pages="totalPages"
			:total-items="totalItems"
			:label-search="getLabelSearch"
			:label-date="$t('OrderFilters.deadline')"
			:statuses="listStatus"
			:sort-options="listSort"
			:show-create-button="isSuperAdmin"
			:show-export-button="true"
			:text-create-button="$t('OrderButtons.create')"
			:types="types"
			:show-highlight-button="showHighlightMode"
			:show-highlight-mode="!showHighlightMode"
			:colors="listColor"
			:color-selected="colorSelected"
			:is-disabled-highlight="!(listOrderSelected.length > 0)"
			:show-created-date="true"
			:date-options="listOptionDate"
			:show-filter-actual-price="showMissingActual"
			:actual-prices="actualPrices"
			@search="search"
			@create="create"
			@selectColor="selectColor"
			@highlightMode="highlightMode"
			@cancelHighlightMode="cancelHighlightMode"
			@saveHighlightMode="saveHighlightMode"
			@exportExcel="exportExcel"
		>
			<template #headers>
				<tr>
					<th
						v-for="(header, index1) in headers"
						:key="`${header.title}-${index1}`"
						scope="col"
						:class="`${header.class}`"
					>
						{{ header.title }}
					</th>
				</tr>
			</template>
			<template #body>
				<template v-if="orders && orders.length">
					<tr
						v-for="(item, index2) in orders"
						:key="`${item.id}-${index2}`"
						:style="setColorHighlight(item)"
					>
						<td v-if="!showHighlightMode" scope="row" class="table-no text-center">
							<b-form-checkbox
								:id="`checkbox-${index2}`"
								:name="`checkbox-${index2}`"
								class="checkbox"
								@change="slectedOrderHighlight($event, item)"
							/>
						</td>
						<td scope="row" class="text-break text-center">
							{{ convertUTCtoDateVN(item.deadLine) }}
						</td>
						<td scope="row" class="text-break text-center">
							{{ convertUTCtoDateVN(item.createdAt) }}
						</td>
						<td scope="row" class="table-no text-center">
							{{ item.id }}
						</td>
						<td scope="row" class="text-break text-center see-more">
							{{ item.title }}
						</td>
						<td v-if="displayWithRole" scope="row" class="text-break text-center">
							{{ item.customerName }}
						</td>
						<td scope="row" class="text-break text-center">
							{{ item.customerId }}
						</td>
						<td scope="row" class="text-break text-center">
							{{ item.amount }}
						</td>
						<td v-if="displayWithRole" scope="row" class="text-break text-center">
							{{ item.customerEmail }}
						</td>

						<td scope="row" class="text-break text-center fit">
							{{ getStatus(item.status) }}
						</td>
						<td scope="row" class="text-break text-center editor">
							<OrderEditor :id="item.id" :index="index2" :value="item.editor" @save="handleEdit" />
						</td>
						<td scope="row" class="text-break text-center note">
							<OrderNote :id="item.id" :index="index2" :value="item.note" @save="handleEdit" />
						</td>
						<td scope="row" class="fit text-center actions">
							<div class="d-flex justify-content-around align-items-center">
								<router-link :to="goToDetail(item)" class="link-icon">
									<span
										class="prefix-input d-inline-block"
										:class="{
											disabled: !showHighlightMode,
										}"
										@click="resetOrders"
									>
										<font-awesome-icon
											:icon="['fas', 'pencil-alt']"
											:title="$t('Action.Edit', item.id)"
										/>
									</span>
								</router-link>
								<template v-if="displayWithRole">
									<span
										v-if="checkIsDelete(item)"
										class="prefix-input d-inline-block trash"
										:class="{ disabled: !showHighlightMode }"
										@click="showConfirmDelete(item.id)"
									>
										<font-awesome-icon :icon="['fas', 'trash-alt']" :title="$t('Action.Delete')" />
									</span>
									<span
										v-else-if="checkShowIconCancel(item)"
										class="prefix-input d-inline-block times"
										:class="{ disabled: !showHighlightMode }"
										@click="showConfirmCancel(item.id)"
									>
										<font-awesome-icon
											:icon="['fas', 'times']"
											class="cancel-icon"
											:title="$t('Action.Cancel')"
										/>
									</span>
								</template>
							</div>
						</td>
					</tr>
				</template>
				<template v-else>
					<tr class="text-center">
						<td :colspan="headers.length" class="fit">
							{{ $t("Table.NoData") }}
						</td>
					</tr>
				</template>
			</template>
		</CustomTable>
		<CustomModal v-if="isDeleteOrder" :submit-text="$t('Button.Yes')" @submit-modal="deleteOrder">
			<template #content>
				{{ $t("OrderMessage.delete_order") }}
			</template>
		</CustomModal>
		<CustomModal
			v-if="isExport"
			:title="$t('OrdersTitle.Export')"
			:is-show-submit="false"
			:is-show-sync="true"
			@cancel-modal="cancelExport"
			@submit-modal="syncToDrive"
		>
			<template #content>
				<CListGroup>
					<CListGroupItem v-if="isSuperAdmin" class="d-flex justify-content-between">
						<span>{{ $t("OrdersTitle.Admin") }}</span>
						<font-awesome-icon
							:icon="['fas', 'file-download']"
							class="icon-file-download"
							@click="handleExportExcel(EXPORT_RULE.ADMIN)"
						/>
					</CListGroupItem>
					<CListGroupItem class="d-flex justify-content-between">
						<span>{{ $t("OrdersTitle.QA") }}</span>
						<font-awesome-icon
							:icon="['fas', 'file-download']"
							class="icon-file-download"
							@click="handleExportExcel(EXPORT_RULE.QA)"
						/>
					</CListGroupItem>
					<CListGroupItem class="d-flex justify-content-between">
						<span>{{ $t("OrdersTitle.Editor") }}</span>
						<font-awesome-icon
							:icon="['fas', 'file-download']"
							class="icon-file-download"
							@click="handleExportExcel(EXPORT_RULE.EDITOR)"
						/>
					</CListGroupItem>
				</CListGroup>
			</template>
		</CustomModal>

		<CustomModal
			v-if="isCancelOrder"
			:submit-text="$t('Button.Confirm')"
			@submit-modal="submitModalCancel"
			@cancel-modal="cancelModalCancel"
		>
			<template #content>
				<div class="form-group row">
					<label for="reason" class="col-12 col-form-label text-break mb-3">{{
						$t("OrderMessage.cancel_order")
					}}</label>
					<div :class="['col-12']">
						<textarea
							id="description"
							v-model.trim="reason"
							type="text"
							rows="6"
							class="form-control remove-resize"
							:placeholder="$t('FormsField.reason')"
							autocomplete="description"
							:maxlength="CANCEL_REASON"
						/>
					</div>
				</div>
			</template>
		</CustomModal>
	</div>
</template>

<script>
import {
	ORDER_STATUS,
	ORDER_SORT,
	USER_ROLE,
	ORDER_RULE,
	EXPORT_RULE,
	CANCEL_REASON,
} from "@/shared/plugins/constants"
import { createNamespacedHelpers } from "vuex"
import {
	GET_ORDERS,
	UPDATE_EDITOR_NOTE,
	DELETE_ORDER,
	EXPORT_ORDER,
	HIGH_LIGHT_ORDER,
	CANCEL_ORDER_WEB,
} from "../store/action-types"
import { RESET_ORDER_FILTERS, SET_ORDERS } from "../store/mutation-types"
const { mapActions, mapState, mapMutations } = createNamespacedHelpers("orders")
import { startCase, capitalize } from "lodash-es"
import {
	COLOR_SCHEMA,
	DATE_OPTIONS,
	EXPORT_TO,
	PARAMS,
	SOURCES,
} from "../../../shared/plugins/constants"
import { GET_TYPES_ORDER } from "../../typeOrder/store/action-types"
import moment from "moment-timezone"
const { mapState: authState } = createNamespacedHelpers("authentication")
export default {
	name: "OrderManagement",
	components: {
		OrderEditor: () => import("../components/OrderEditor.vue"),
		OrderNote: () => import("../components/OrderNote.vue"),
	},
	data() {
		return {
			ORDER_RULE,
			EXPORT_RULE,
			CANCEL_REASON,
			isEditEditor: false,
			isEditNote: false,
			isDeleteOrder: false,
			isCancelOrder: false,
			isExport: false,
			isSync: false,
			showHighlightMode: true,
			OrderID: null,
			valueEditor: null,
			reason: null,
			limit: PARAMS.LIMIT,
			offset: PARAMS.OFFSET,
			types: [],
			listOrderSelected: [],
			colorSelected: {
				name: this.$t("DropDown.White"),
				value: COLOR_SCHEMA.WHITE,
			},
			headers: [
				{
					title: this.$t("OrderFieldTable.Deadline"),
					class: "text-nowrap text-center date",
				},
				{
					title: this.$t("OrderFieldTable.CreatedAt"),
					class: "text-nowrap text-center date",
				},
				{
					title: this.$t("OrderFieldTable.ID"),
					class: "text-center fit-id",
				},
				{
					title: this.$t("OrderFieldTable.Title"),
					class: "text-nowrap title text-center",
				},
				{
					title: this.$t("OrderFieldTable.CustomerName"),
					class: "text-nowrap text-center",
				},
				{
					title: this.$t("OrderFieldTable.CustomerID"),
					class: "text-nowrap text-center fit",
				},
				{
					title: this.$t("OrderFieldTable.Amount"),
					class: "text-nowrap text-center",
				},
				{
					title: this.$t("OrderFieldTable.Email"),
					class: "text-nowrap text-center email",
				},
				{
					title: this.$t("OrderFieldTable.Status"),
					class: "text-nowrap text-center fit",
				},
				{
					title: this.$t("OrderFieldTable.Editor"),
					class: "text-nowrap text-center editor",
				},
				{
					title: this.$t("OrderFieldTable.Note"),
					class: "text-nowrap text-center note",
				},
				{
					title: this.$t("OrderFieldTable.Actions"),
					class: "fit text-center",
				},
			],
			listStatus: [
				{
					name: this.$t("OrderFilterName.complete"),
					value: ORDER_STATUS.COMPLETED,
				},
				{
					name: this.$t("OrderFilterName.new_job"),
					value: ORDER_STATUS.NEW_JOB,
				},
				{
					name: this.$t("OrderFilterName.revision"),
					value: ORDER_STATUS.REVISION,
				},
				{
					name: this.$t("OrderFilterName.more_new_job"),
					value: ORDER_STATUS.MORE_NEW_JOB,
				},
				{
					name: this.$t("OrderFilterName.revision_more_new_job"),
					value: ORDER_STATUS.REVISION_MORE_NEW_JOB,
				},
				{
					name: this.$t("OrderFilterName.cancelled"),
					value: ORDER_STATUS.CANCELLED,
				},
			],
			listSort: [
				{
					name: this.$t("OrderFilterName.deadline"),
					value: ORDER_SORT.DEADLINE,
				},
				{
					name: this.$t("OrderFilterName.updatedTime"),
					value: ORDER_SORT.UPDATED_AT,
				},
			],
			listColor: [
				{
					name: this.$t("DropDown.White"),
					value: COLOR_SCHEMA.WHITE,
				},
				{
					name: this.$t("DropDown.Yellow"),
					value: COLOR_SCHEMA.YELLOW,
				},
				{
					name: this.$t("DropDown.Green"),
					value: COLOR_SCHEMA.GREEN,
				},
				{
					name: this.$t("DropDown.Red"),
					value: COLOR_SCHEMA.RED,
				},
			],
			listOptionDate: [
				{
					name: this.$t("OrderFilterName.today"),
					value: DATE_OPTIONS.TODAY,
				},
				{
					name: this.$t("OrderFilterName.week"),
					value: DATE_OPTIONS.WEEK,
				},
				{
					name: this.$t("OrderFilterName.month"),
					value: DATE_OPTIONS.MONTH,
				},
				{
					name: this.$t("OrderFilterName.year"),
					value: DATE_OPTIONS.YEAR,
				},
			],
			actualPrices: [
				{
					name: this.$t("OrderFilterName.yes"),
					value: ORDER_SORT.YES,
				},
				{
					name: this.$t("OrderFilterName.no"),
					value: ORDER_SORT.NO,
				},
			],
		}
	},
	computed: {
		...mapState([
			"orders",
			"totalItems",
			"totalPages",
			"recentFilters",
			"fileExport",
			"roleExport",
		]),
		...authState(["canLogin", "currentUser"]),
		isSuperAdmin() {
			if (this.currentUser?.role !== USER_ROLE.QA) {
				return true
			}
			return false
		},

		displayWithRole() {
			if (this.currentUser?.role !== USER_ROLE.QA) {
				return true
			}
			return false
		},
		getLabelSearch() {
			return this.isSuperAdmin
				? this.$t("OrderFilters.searchByAdmin")
				: this.$t("OrderFilters.searchByQA")
		},

		showMissingActual() {
			if (this.currentUser.role === USER_ROLE.SUPER_ADMIN) {
				return true
			}
			return false
		},
	},
	watch: {
		fileExport(val) {
			if (!val || this.isSync) return
			const url = window.URL.createObjectURL(new Blob([val]))
			const link = document.createElement("a")
			link.href = url
			link.setAttribute("download", `order_${this.roleExport}.xlsx`)
			document.body.appendChild(link)
			link.click()
		},
		showHighlightMode() {
			this.headerWithRole()
		},
	},
	beforeDestroy() {
		if (!this.$route.path.includes("orders")) this.RESET_ORDER_FILTERS()
	},
	mounted() {
		this.headerWithRole()
	},
	created() {
		this.getTypes()
	},
	methods: {
		...mapActions({
			GET_ORDERS,
			UPDATE_EDITOR_NOTE,
			DELETE_ORDER,
			EXPORT_ORDER,
			HIGH_LIGHT_ORDER,
			CANCEL_ORDER_WEB,
		}),
		...mapMutations({ RESET_ORDER_FILTERS, SET_ORDERS }),
		async search(params, notLoading = false) {
			const data = {
				data: params,
				notLoading: notLoading,
			}
			await this.GET_ORDERS(data)
		},
		create() {
			this.$router.push({ name: "CreateOrder" })
		},
		async handleEdit(params) {
			await this.UPDATE_EDITOR_NOTE(params)
		},
		getStatus(status) {
			if (status === ORDER_STATUS.REVISION_MORE_NEW_JOB) {
				return this.$t("OrderFilterName.revision_more_new_job")
			}
			return startCase(capitalize(status))
		},
		goToDetail(item) {
			if (!this.showHighlightMode)
				return {
					query: { ...this.recentFilters },
				}

			if (item.source === SOURCES.WEB) {
				return {
					name: "OrderWebDetail",
					params: { id: item.id },
				}
			}
			return {
				name: "OrderDetail",
				params: { id: item.id },
			}
		},
		resetOrders() {
			if (!this.showHighlightMode) return
			this.SET_ORDERS([])
		},
		showConfirmDelete(id) {
			if (!this.showHighlightMode) return
			this.OrderID = id
			this.$store.commit("set", ["modalShow", true])
			this.isDeleteOrder = true
		},
		async deleteOrder() {
			const params = {
				originalId: this.OrderID,
			}
			await this.DELETE_ORDER(params)
			this.OrderID = null
			this.$store.commit("set", ["modalShow", false])
			this.isDeleteOrder = false
		},
		checkIsDelete(item) {
			if (item.source === SOURCES.WEB) {
				return false
			}
			return item.canDelete
		},
		checkShowIconCancel(item) {
			return item.status === ORDER_STATUS.NEW_JOB ? true : false
		},
		exportExcel() {
			this.$store.commit("set", ["modalShow", true])
			this.isExport = true
		},
		cancelExport() {
			this.isExport = false
		},
		async handleExportExcel(type) {
			const params = {
				...this.recentFilters,
				limit: null,
				offset: null,
				roleExport: type,
				timezone: moment.tz.guess(),
				exportTo: EXPORT_TO.LOCAL,
			}
			await this.EXPORT_ORDER(params)
		},
		async getTypes() {
			const params = {
				data: {
					// limit: this.limit,
					// offset: this.offset,
				},
				notLoading: true,
			}
			this.types = await this.$store.dispatch(`typeOrder/${GET_TYPES_ORDER}`, params)
			return this.types
		},
		selectColor(color) {
			this.colorSelected = color
		},
		highlightMode() {
			this.showHighlightMode = false
		},
		cancelHighlightMode() {
			this.showHighlightMode = true
			this.headers.shift()
			this.listOrderSelected = []
		},
		slectedOrderHighlight(value, order) {
			if (value) {
				this.listOrderSelected.push(order)
			} else {
				const deleteIndex = this.listOrderSelected.findIndex(el => el.id === order.id)
				this.listOrderSelected.splice(deleteIndex, 1)
			}
		},
		async saveHighlightMode() {
			const params = {
				originalOrderIds: this.getOriginalOrderIds(),
				settings: {
					color: this.colorSelected.value,
				},
			}
			await this.HIGH_LIGHT_ORDER(params)
			this.cancelHighlightMode()
		},
		getOriginalOrderIds() {
			if (this.listOrderSelected.length) {
				return this.listOrderSelected.map(el => el.id)
			}
		},
		setColorHighlight(order) {
			if (order.settings.color) {
				return {
					background: order.settings.color,
				}
			}
			return {
				background: COLOR_SCHEMA.WHITE,
			}
		},
		headerWithRole() {
			if (!this.showHighlightMode) {
				// const newHeader = [...this.headers]
				this.headers.unshift({
					title: "",
					class: "fit-id text-center",
				})
				// return newHeader
			}

			if (this.currentUser && !this.isSuperAdmin) {
				this.headers = this.headers.filter(
					el =>
						el.title !== this.$t("OrderFieldTable.Email") &&
						el.title !== this.$t("OrderFieldTable.CustomerName"),
				)
			}

			return this.headers
		},
		async syncToDrive() {
			this.isSync = true
			const params = {
				...this.recentFilters,
				limit: null,
				offset: null,
				roleExport: EXPORT_RULE.EDITOR,
				timezone: moment.tz.guess(),
				exportTo: EXPORT_TO.DRIVE,
			}
			await this.EXPORT_ORDER(params)
			this.$store.commit("set", ["modalShow", false])
			this.isExport = false
			this.isSync = false
		},
		showConfirmCancel(id) {
			if (!this.showHighlightMode) return
			this.OrderID = id
			this.isCancelOrder = true
			this.$store.commit("set", ["modalShow", true])
		},
		async submitModalCancel() {
			this.showLoading()
			const params = {
				orderId: this.OrderID,
				cancelReason: this.reason,
			}
			const res = await this.CANCEL_ORDER_WEB(params)
			if (res) {
				await this.search(this.recentFilters, true)
				this.$store.commit("set", ["modalShow", false])
				this.cancelModalCancel()
			}
			this.hideLoading()
		},
		cancelModalCancel() {
			this.isCancelOrder = false
			this.reason = null
			this.OrderID = null
		},
	},
}
</script>

<style lang="scss" scoped>
.cursor-pointer {
	cursor: pointer;
}
.icon-file-download {
	font-size: 20px;
	cursor: pointer;
}

.disabled {
	opacity: 0.5;
}
</style>

<style lang="scss">
.customer-multiselect {
	.multiselect__tags {
		max-height: 50px;
		overflow-y: auto;
	}
}
</style>
